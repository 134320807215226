/* eslint-disable @typescript-eslint/naming-convention */
// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { DefinedQueryObserverResult, QueryKey, QueryObserverResult } from '@tanstack/query-core';
import { DefinedInitialDataOptions, UndefinedInitialDataOptions } from '@ngneat/query/lib/query-options';
import { Result } from '@ngneat/query/lib/types';
import { InfiniteData, InfiniteQueryObserverResult } from '@ngneat/query';
import { CreateInfiniteQueryOptions } from '@ngneat/query/lib/infinite-query';

export interface ICompareBase {
  compareDateRange: string;
}

export enum IApiMethod {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  delete = 'DELETE'
}

export enum ICacheKey {
  GET_CONTRIBUTORS_LEADERBOARD = 'getContributorsLeaderboard',
  GET_CONTRIBUTORS_COUNTRY = 'getContributorsCountry',
  GET_CONTRIBUTOR_BUS_FACTOR_PAGINATE = 'getContributorBusFactorPaginate',
  GET_CONTRIBUTOR_BUS_FACTOR = 'getContributorBusFactor',
  GET_ORG_BUS_FACTOR_PAGINATE = 'getOrgBusFactorPaginate',
  GET_ORG_BUS_FACTOR = 'getOrgBusFactor',
  GET_ACTIVE_DAYS_CONTRIBUTORS = 'getActiveDaysContributors',
  GET_ACTIVE_COMMIT_TIMESTAMP = 'getActivitiesCommitTimestamp',
  GET_ACTIVITIES_CONTRIBUTOR_COUNT = 'getActivitiesContributorCount',
  GET_TOTAL_CONTRIBUTOR_COUNT = 'getTotalContributorCount',
  GET_TOTAL_COMMIT_COUNT = 'getTotalCommitCount',
  GET_TOTAL_ISSUES_COUNT = 'getTotalIssuesCount',
  GET_TOTAL_PR_COUNT = 'getTotalPRCount',
  GET_TOTAL_FORKS_COUNT = 'getTotalForksCount',
  GET_TOTAL_STARS_COUNT = 'getTotalStarsCount',
  GET_CONTRIBUTORS_SUMMMARY = 'getContributorsSummary',
  GET_COMMITS_SUMMARY = 'getCommitsSummary',
  GET_ISSUES_SUMMARY = 'getIssuesSummary',
  GET_PR_SUMMARY = 'getPRSummary',
  GET_FORKS_SUMMARY = 'getForksSummary',
  GET_STARS_SUMMARY = 'getStarsSummary',
  GET_RETENTION_BREAKDOWN = 'getRetentionBreakdown',
  GET_RETENTION_SUMMARY = 'getRetentionSummary',
  GET_TOTAL_ACTIVITIES_CUMULATIVE = 'getTotalActivitiesCumulative',
  GET_ACTIVITY_SUMMARY = 'getActivitySummary',
  GET_NEW_ACTIVITIES = 'getNewActivities',
  GET_ACTIVITY_DETAILS = 'getActivityDetails',
  GET_ACTIVITIES_BY_GEO = 'getActivitiesByGeo',
  GET_ACTIVITIES_BY_TYPE = 'getActivitiesByType',
  GET_CONTRIBUTORS_TOTAL = 'getContributorsTotal',
  GET_NEW_CONTRIBUTORS_TOTAL = 'getNewContributorsTotal',
  GET_ACTIVITIES_CONTRIBUTOR_COUNT_REPORT = 'getActivitiesContributorCountReport',
  GET_ACTIVE_CONTRIBUTORS = 'getActiveContributors',
  GET_CONT_LEADERBOARD_MOST_ACTIVE_TOTAL = 'getContLeaderboardMostActiveTotal',
  GET_CONT_LEADERBOARD_MOST_ACTIVE = 'getContLeaderboardMostActive',
  GET_TOTAL_ACTIVE_ORGANIZATIONS = 'getTotalActiveOrganizations',
  GET_ACTIVITIES_ORG_COUNT_REPORT = 'getActivitiesOrgCountReport',
  GET_ORG_LEADERBOARD_MOST_ACTIVE = 'getOrgLeaderboardMostActive',
  GET_ORG_LEADERBOARD_MOST_ACTIVE_TOTAL = 'getOrgLeaderboardMostActiveTotal',
  GET_NEW_ORGS_REPORT = 'getNewOrgsReport',
  GET_ACTIVE_ORGS_REPORT = 'getActiveOrgsReport',
  GET_RETURNING_ORGS_REPORT = 'getReturningOrgsReport',
  GET_CURRENT_CUMULATIVE_CONTRIBUTOR = 'getCurrentAndCumulativeContributor',
  GET_SUMMARY_COMMITS = 'getSummaryCommits',
  GET_SUMMARY_ISSUES = 'getSummaryIssues',
  GET_SUMMARY_PRS = 'getSummaryPRs',
  GET_SUMMARY_FORKS = 'getSummaryForks',
  GET_SUMMARY_STARS = 'getSummaryStars',
  GET_MAILING_LIST_COUNTRY = 'getMailingListCountry',
  GET_MAILING_LIST_GROUPS = 'getMailingListGroups',
  GET_MAILING_LIST_TOP_TILE = 'getMailingListTopTile',
  GET_MAILING_LIST_SUMMARY_CHARTS = 'getMailingSummaryCharts',
  GET_MAILING_NEW_CONTRIBUTORS = 'getMailingNewContributors',
  GET_MAILING_ACTIVE_CONTRIBUTORS = 'getMailingActiveContributors',
  GET_MAILING_NEW_ORGS = 'getMailingNewOrgs',
  GET_MAILING_ACTIVE_ORGS = 'getMailingActiveOrgs',
  GET_TOP_MAILING_LIST = 'getTopMailingList',
  GET_RECENT_MESSAGES = 'getRecentMessages',
  GET_POPULAR_THREADS = 'getPopularThreads',
  GET_PR_LEAD_TIME = 'getPRLeadTime',
  GET_GERRIT_CONTRIBUTORS_TILE = 'getContributorsTile',
  GET_GERRIT_COMMITS_TILE = 'getCommitsTile',
  GET_GERRIT_CHANGESETS_TILE = 'getChangesetsTile',
  GET_GERRIT_CONTRIBUTORS_CHART = 'getGerritContributorsChart',
  GET_GERRIT_COMMITS_CHART = 'getGerritCommitsChart',
  GET_GERRIT_CHANGESETS_CHART = 'getGerritChangesetsChart',
  GET_LEAD_TIME_BY_PR_SIZE = 'getLeadTimeByPRSize',
  GET_REVIEW_TIME_BY_PR_SIZE = 'getReviewTimeByPRSize',
  GET_WAIT_TIME_FOR_1ST_REVIEW = 'getWaitTimeFor1stReview',
  GET_WAIT_TIME_FOR_1ST_REVIEW_TOTAL = 'getWaitTimeFor1stReviewTotal',
  GET_CONFLUENCE_ACTIVITIES_TILE = 'getConfluenceActivitiesTile',
  GET_CONFLUENCE_CONTRIBUTORS_TILE = 'getConfluenceContributorsTile',
  GET_CONFLUENCE_FIRST_CONTRIBUTORS_TILE = 'getConfluenceFirstContributorsTile',
  GET_CONFLUENCE_CONTRIBUTOR_LEADERBOARD = 'getConfluenceContributorLeaderboard',
  GET_CONFLUENCE_ACTIVITIES_BREAKDOWN = 'getConfluenceActivitiesBreakdown',
  GET_CONFLUENCE_ORGANIZATION_LEADERBOARD = 'getConfluenceOrganizationLeaderboard',
  GET_CONFLUENCE_NEW_ORGANIZATIONS = 'getConfluenceNewOrganizations',
  GET_CONFLUENCE_ORGANIZATION_DRIFTING_AWAY = 'getConfluenceOrganizationDriftingAway',
  GET_CONFLUENCE_ORGANIZATION_INVOLVE = 'getConfluenceOrganizationInvolve',
  GET_CONFLUENCE_ACTIVITIES_BY_WEEK = 'getConfluenceActivitiesByWeek',
  GET_CONFLUENCE_MOST_POPULAR_PAGES = 'getConfluenceMostPopularPages',
  GET_CONFLUENCE_GEO_DISTRIBUTION = 'getConfluenceGeoDistribution',
  GET_CONFLUENCE_SUMMARY_CHART = 'getConfluenceSummaryChart',
  GET_CONFLUENCE_SUMMARY_LEGENDS = 'getConfluenceSummaryLegends',
  GET_CONFLUENCE_SUMMARY_CUMULATIVE_CHART = 'getConfluenceSummaryCumulativeChart',
  GET_CONFLUENCE_NEW_CONTRIBUTORS = 'getConfluenceNewContributors',
  GET_CONFLUENCE_DRIFT_CONTRIBUTORS = 'getConfluenceDriftContributors',
  GET_BP_SCORES = 'getBestPracticeScores',
  GET_BP_SCORES_ALL = 'getBestPracticeScoresAll',
  GET_BP_SCORE_DETAILS = 'getBestPracticeScoreRepoDetail',
  GET_BP_REPORT = 'getBestPracticeReport',
  GET_FOUNDATION_SEGMENTS = 'getFoundationSegments',
  GET_FOUNDATION_SEGMENT_BY_SLUG = 'getFoundationSegmentBySlug',
  GET_FOUNDATION_PROJECT_SEGMENTS_BY_SLUG = 'getFoundationProjectSegmentsBySlug',
  GET_FOUNDATION_DETAILS = 'getFoundationDetails',
  GET_FOUNDATION_SOURCES = 'getFoundationSources',
  GET_FOUNDATION_ALL_TIME_STATS = 'getFoundationAllTimeStats',
  GET_FOUNDATION_PROJECT_KEY_METRICS = 'getFoundationProjectKeyMetrics',
  GET_ACTIVE_CONTRIBUTORS_KEY_METRICS = 'getActiveContributorsKeyMetric',
  GET_FOUNDATION_COCOMO_BY_SLUG = 'getFoundationCOCOMOBySlug',
  GET_FOUNDATION_COCOMO_BY_SEGMENT_ID = 'getFoundationCOCOMOBySegmentID',
  GET_FOUNDATION_PROJECT_VELOCITY = 'getFoundationProjectsVelocity',
  GET_FOUNDATION_PROJECT_MATURITY_LEVEL = 'getFoundationProjectMaturityLevel',
  GET_FOUNDATION_METRICS = 'getFoundationMetrics',
  GET_ORGANIZATION_LEADERBOARD = 'getSnowOrganizationLeaderboard',
  GET_COMMITS_PER_DAY = 'getCommitPerDay',
  GET_COMMITS_PER_DAY_VARIATION = 'getCommitPerDayVariation',
  GET_NEW_CONTRIBUTORS = 'getNewContributors',
  GET_PULL_REQUESTS_PARTICIPANTS_SUMMARY = 'getPullRequestsParticipantsSummary',
  GET_TOTAL_PULL_REQUESTS_CODE_REVIEWS = 'getTotalPullRequestsCodeReviews',
  GET_TOTAL_PULL_REQUESTS_REVIEW_COMMENTS = 'getTotalPullRequestsReviewComments',
  GET_TOTAL_PULL_REQUESTS_REVIEWED = 'getTotalPullRequestsReviewed',
  GET_PULL_REQUESTS_CODE_REVIEWS = 'getPullRequestsCodeReviews',
  GET_PULL_REQUESTS_REVIEW_COMMENTS = 'getPullRequestsReviewComments',
  GET_PULL_REQUESTS_REVIEWED = 'getPullRequestsReviewed',
  GET_WORK_TIME_DISTRIBUTION_HOURS = 'getWorkTimeDistributionHours',
  GET_ENGAGEMENT_GAP = 'getEngagementGap',
  GET_EFFORT_BY_P_R_BATCH_SIZE_TIMELINE = 'getEffortByPullRequestBatchSizeTimeline',
  GET_DRIFTING_AWAY_CONTRIBUTORS = 'getDriftingContributors',
  GET_CONTRIBUTOR_DIVERSIFICATION = 'getContributorDiversification',
  GET_PROJECT_HEALTH_FORKS = 'getProjectHealthForks',
  GET_PROJECT_HEALTH_STARS = 'getProjectHealthStars',
  GET_PROJECT_HEALTH_SCORES = 'getProjectHealthScores',
  GET_ALL_FOUNDATION_CARDS = 'getAllFoundationCards',
  GET_ALL_PROJECTS = 'getAllProjects',
  GET_FOUNDATION_AND_PROJECTS = 'getFoundationAndProjectsBySlug',
  GET_REPOSITORIES_BY_SLUG = 'getRepositoriesBySlug'
}

export type InjectQuery = {
  <TQueryFnData = unknown, TError = Error, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey>(
    options: UndefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey>
  ): Result<QueryObserverResult<TData, TError>>;
  <TQueryFnData_1 = unknown, TError_1 = Error, TData_1 = TQueryFnData_1, TQueryKey_1 extends QueryKey = QueryKey>(
    options: DefinedInitialDataOptions<TQueryFnData_1, TError_1, TData_1, TQueryKey_1>
  ): Result<DefinedQueryObserverResult<TData_1, TError_1>>;
};

export type InjectInfiniteQuery = {
  <
    TQueryFnData,
    TError = Error,
    TData = InfiniteData<TQueryFnData, unknown>,
    TQueryKey extends QueryKey = QueryKey,
    TPageParam = unknown
  >(
    options: CreateInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey, TPageParam>
  ): Result<InfiniteQueryObserverResult<TData, TError>>;
};

<!--
  ~ Copyright The Linux Foundation and each contributor to LFX.
  ~ SPDX-License-Identifier: MIT
  -->
<div
  class="group-tab"
  [ngClass]="{
    active: active(),
    'hover:bg-primary/5': isHoverEnabled(),
    '2xl:w-auto 2xl:flex ': true,
    'cursor-pointer': isHoverEnabled()
  }"
  [attr.data-id]="dataId()"
  data-role="tile">
  <div class="group-left grid" [ngClass]="{ '2xl:block': true }">
    <div class="text-tiny flex">
      @if (icon(); as icon) {
        <span class="inline-block pr-1">
          <fa-icon [icon]="icon"> </fa-icon>
        </span>
      }
      <span data-role="title" class="text-gray-dark font-sans capitalize whitespace-nowrap"> {{ title() }} </span>
    </div>
    @if (!isLoading()) {
      @if (!isError()) {
        <div class="font-sans text-gray-dark flex items-center space-x-2 pt-1">
          <span data-role="value" class="data-pt-2 whitespace-nowrap">{{ mainValue() | validVarType }}</span>
          @if (secondaryValue() !== null) {
            <span
              class="text-tiny"
              [ngClass]="{
                'text-red-dark': secondaryValueColorType() === 'negative',
                'text-green': secondaryValueColorType() === 'positive'
              }"
              data-role="delta">
              {{ secondaryValue() | lfxShortNumber: 1 | integerSign }}%
            </span>
          }
        </div>
      } @else {
        <lfx-error [isCompact]="true"></lfx-error>
      }
    } @else {
      <div class="pt-1">
        <!-- <lfx-loading [minHeight]="34"></lfx-loading> -->
        <p-skeleton width="70px" height="21px"></p-skeleton>
      </div>
    }
  </div>
</div>

// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { BffConstants } from '@services/api/bff.constants';
import { BFFServiceBasic } from '@services/api/bff.basic';
import { BffService } from '@services/bff.service';
import { InsightsFiltersNew } from '@services/filter/filter.types';
import { InsightProject } from 'lfx-insights';
import { ActivatedRoute } from '@angular/router';
import { inject } from '@angular/core';
import { getChartQueryParam } from '@app/shared/queries/summary-charts/query';

export interface ISummaryChartBase {
  BUCKET_DT_FROM: string;
  BUCKET_DT_TO: string;
  IS_SUMMARY: boolean;
}
export interface IContributorsChart extends ISummaryChartBase {
  CONTRIBUTORS: number;
  CUMULATIVE_CONTRIBUTORS: number;
}

export interface ICommitsChart extends ISummaryChartBase {
  COMMITS: number;
  CUMULATIVE_COMMITS: number;
}

export interface IIssuesChart extends ISummaryChartBase {
  ISSUES_OPENED: number;
  ISSUES_CLOSED: number;
  CUMULATIVE_ISSUES: number;
}

export interface IPRsChart extends ISummaryChartBase {
  PRS_OPENED: number;
  PRS_CLOSED_UNMERGED: number;
  PRS_CLOSED_MERGED: number;
  CUMULATIVE_PRS: number;
}

export interface IForksChart extends ISummaryChartBase {
  FORKS: number;
  CUMULATIVE_FORKS: number;
}

export interface IStarsChart extends ISummaryChartBase {
  STARS: number;
  CUMULATIVE_STARS: number;
}

export class SummaryChartsApiClass extends BFFServiceBasic {
  protected useInfiniteQuery;
  protected useQuery: InjectQuery;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private cacheByPass =
    (this.activatedRoute.snapshot.queryParamMap.has('cache-bypass') &&
      this.activatedRoute.snapshot.queryParamMap.get('cache-bypass') === 'true') ||
    undefined;

  constructor(bffService: BffService, useInfiniteQuery: InjectInfiniteQuery, useQuery: InjectQuery) {
    super(bffService);
    this.useInfiniteQuery = useInfiniteQuery;
    this.useQuery = useQuery;
  }

  public getContributorsSummary(filters: InsightsFiltersNew, project: InsightProject, enabled: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_CONTRIBUTORS_SUMMMARY, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getChartQueryParam(
          filters,
          project,
          ['CONTRIBUTORS', 'CUMULATIVE_CONTRIBUTORS'],
          this.cacheByPass
        );
        return this.loadData<IContributorsChart[]>(BffConstants.GLOBAL_OVERVIEW, query);
      },
      enabled
    });
  }

  public getCommitsSummary(filters: InsightsFiltersNew, project: InsightProject, enabled: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_COMMITS_SUMMARY, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getChartQueryParam(filters, project, ['COMMITS', 'CUMULATIVE_COMMITS'], this.cacheByPass);
        return this.loadData<ICommitsChart[]>(BffConstants.GLOBAL_OVERVIEW, query);
      },
      enabled
    });
  }

  public getIssuesSummary(filters: InsightsFiltersNew, project: InsightProject, enabled: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_ISSUES_SUMMARY, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getChartQueryParam(
          filters,
          project,
          ['ISSUES_OPENED', 'ISSUES_CLOSED', 'CUMULATIVE_ISSUES'],
          this.cacheByPass
        );
        return this.loadData<IIssuesChart[]>(BffConstants.GLOBAL_OVERVIEW, query);
      },
      enabled
    });
  }

  public getPRSummary(filters: InsightsFiltersNew, project: InsightProject, enabled: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_PR_SUMMARY, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getChartQueryParam(
          filters,
          project,
          ['PRS_OPENED', 'PRS_CLOSED_UNMERGED', 'PRS_CLOSED_MERGED', 'CUMULATIVE_PRS'],
          this.cacheByPass
        );
        return this.loadData<IPRsChart[]>(BffConstants.GLOBAL_OVERVIEW, query);
      },
      enabled
    });
  }

  public getForksSummary(filters: InsightsFiltersNew, project: InsightProject, enabled: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_FORKS_SUMMARY, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getChartQueryParam(filters, project, ['FORKS', 'CUMULATIVE_FORKS'], this.cacheByPass);
        return this.loadData<IForksChart[]>(BffConstants.GLOBAL_OVERVIEW, query);
      },
      enabled
    });
  }

  public getStarsSummary(filters: InsightsFiltersNew, project: InsightProject, enabled: boolean) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_STARS_SUMMARY, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getChartQueryParam(filters, project, ['STARS', 'CUMULATIVE_STARS'], this.cacheByPass);
        return this.loadData<IStarsChart[]>(BffConstants.GLOBAL_OVERVIEW, query);
      },
      enabled
    });
  }
}

// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { BFFServiceBasic } from '../bff.basic';
import { BffService } from '../../bff.service';
import { BffConstants } from '../bff.constants';
import {
  contributorDependencyFields,
  getDependencyQuery,
  orgDependencyFields
} from '@app/shared/queries/bus-factor/bus-factor';
import { InsightsFiltersNew } from '../../filter/filter.types';
import { InsightProject } from 'lfx-insights';

export interface IBusFactorBase {
  ROW_NUMBER: number;
  CNT: number;
  PREV_CNT: number;
  DELTA: number;
  PERCENT: number;
  CUMULATIVE_PERCENT: number;
  CHANGE_FROM_PREVIOUS: number;
  BUS_FACTOR: number;
  MIN_PERCENT: number;
  OTHERS_COUNT: number;
  OTHERS_PERCENT: number;
}

export interface IContBusFactor extends IBusFactorBase {
  SEGMENT_ID: string;
  MEMBER_ID: string;
  JOINED_AT: string;
  MEMBER_DISPLAY_NAME: string;
  LOGO_URL: string;
  COUNTRY: string;
}

export interface IOrgBusFactor extends IBusFactorBase {
  ORG_ID: string;
  ORG_NAME: string;
  ORG_LOGO_URL: string;
  ORG_CREATED_AT: string;
}

export class BusFactorApiClass extends BFFServiceBasic {
  protected useQuery: InjectQuery;
  protected useInfiniteQuery;

  constructor(bffService: BffService, useQuery: InjectQuery, useInfiniteQuery: InjectInfiniteQuery) {
    super(bffService);
    this.useQuery = useQuery;
    this.useInfiniteQuery = useInfiniteQuery;
  }

  public getContributorBusFactorPaginate(
    filter: InsightsFiltersNew,
    activityType: string,
    limit: number,
    startingOffset: number,
    isGerritProject: boolean,
    project: InsightProject | undefined
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_CONTRIBUTOR_BUS_FACTOR_PAGINATE,
        filter.project,
        filter.repositories,
        filter.dateFilters,
        filter.periods,
        activityType,
        startingOffset,
        limit,
        isGerritProject
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getDependencyQuery(
          contributorDependencyFields,
          filter,
          activityType,
          limit,
          pageParam + startingOffset,
          isGerritProject,
          project!
        );
        return this.loadData<IContBusFactor[]>(BffConstants.CONTRIBUTOR_BUS_FACTOR, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getContributorBusFactor(
    filter: InsightsFiltersNew,
    activityType: string,
    limit: number,
    offset: number,
    isDownloadCSV: boolean,
    isGerritProject: boolean,
    project: InsightProject | undefined,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_CONTRIBUTOR_BUS_FACTOR,
        filter,
        activityType,
        limit,
        offset,
        isGerritProject,
        project
      ] as const,
      queryFn: () => {
        const query = getDependencyQuery(
          contributorDependencyFields,
          filter,
          activityType,
          limit,
          offset,
          isGerritProject,
          project!
        );
        return this.loadData<IContBusFactor[]>(BffConstants.CONTRIBUTOR_BUS_FACTOR, query, options);
      },
      enabled: isDownloadCSV && !!project
    });
  }

  public getOrgBusFactorPaginate(
    filter: InsightsFiltersNew,
    activityType: string,
    limit: number,
    startingOffset: number,
    isGerritProject: boolean,
    project: InsightProject | undefined
  ) {
    return this.useInfiniteQuery({
      queryKey: [
        ICacheKey.GET_ORG_BUS_FACTOR_PAGINATE,
        filter.project,
        filter.repositories,
        activityType,
        startingOffset,
        limit,
        isGerritProject
      ] as const,
      queryFn: ({ pageParam }) => {
        const query = getDependencyQuery(
          orgDependencyFields,
          filter,
          activityType,
          limit,
          pageParam + startingOffset,
          isGerritProject,
          project!
        );
        return this.loadData<IOrgBusFactor[]>(BffConstants.ORG_BUS_FACTOR, query);
      },
      enabled: !!project,
      initialPageParam: 0,
      getNextPageParam: (lastPage, allPages) => {
        const nextOffset = allPages.length * limit;
        return lastPage.length === limit ? nextOffset : undefined;
      }
    });
  }

  public getOrgBusFactor(
    filter: InsightsFiltersNew,
    activityType: string,
    limit: number,
    offset: number,
    isDownloadCSV: boolean,
    isGerritProject: boolean,
    project: InsightProject | undefined,
    options: { signal?: AbortSignal; [key: string]: any } = {}
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ORG_BUS_FACTOR,
        filter,
        project,
        limit,
        offset,
        isGerritProject,
        isDownloadCSV,
        activityType
      ] as const,
      queryFn: () => {
        const query = getDependencyQuery(
          orgDependencyFields,
          filter,
          activityType,
          limit,
          offset,
          isGerritProject,
          project!
        );
        return this.loadData<IOrgBusFactor[]>(BffConstants.ORG_BUS_FACTOR, query, options);
      },
      enabled: isDownloadCSV && !!project
    });
  }
}

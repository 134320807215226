// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { map } from 'rxjs';
import { PivotConfig, Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { ICacheKey, ICompareBase, InjectQuery } from '@services/api/api.types';
import { InsightsFilters } from '@services/filter.service';
import { CubeService } from '../../cube.service';
import { IActivitiesCommitCount, ISummaryCommits } from './CommitsApiClass';

export interface IGerrtiChartBase {
  x: string; // contains date
}

export interface IGerritContributorsTile extends ICompareBase {
  ['SnowGerritActivities.metric_contributors']: number;
}

export interface IGerritChangesetsTile extends ICompareBase {
  ['SnowChangeSetsOverview.totalUniqueChangeSetsCount']: number;
}

export interface IGerritContributorsChart extends IGerrtiChartBase {
  ['SnowGerritActivities.metric_contributors']: number;
  ['SnowGerritActivities.cumulative_members']: number;
}

export interface IGerritChangesetsChart extends IGerrtiChartBase {
  ['SnowChangeSetsOverview.opened_count']: number;
  ['SnowChangeSetsOverview.abandoned_count']: number;
  ['SnowChangeSetsOverview.merged_count']: number;
  ['SnowChangeSetsOverview.cumulative_changesets']: number;
}

export class GerritApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getContributorsTile(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_GERRIT_CONTRIBUTORS_TILE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IGerritContributorsTile>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getCommitsTile(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_GERRIT_COMMITS_TILE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IActivitiesCommitCount>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getChangesetsTile(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_GERRIT_CHANGESETS_TILE,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<IGerritChangesetsTile>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getGerritContributorsChart(
    queries: Query[],
    filters: InsightsFilters,
    pivotConfig: PivotConfig,
    isMocked: boolean = false,
    filterExclusions?: string[]
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_GERRIT_CONTRIBUTORS_CHART,
        filters.project,
        filters.repositories,
        filters.hideBots,
        queries[0].timeDimensions
      ],
      queryFn: () =>
        this.load$<IGerritContributorsChart>(queries, isMocked, filterExclusions).pipe(
          map((res) => this.toChartSeries(res, pivotConfig))
        )
    });
  }

  public getGerritCommitsChart(
    queries: Query[],
    filters: InsightsFilters,
    pivotConfig: PivotConfig,
    isMocked: boolean = false
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_GERRIT_COMMITS_CHART,
        filters.project,
        filters.repositories,
        filters.hideBots,
        queries[0].timeDimensions
      ],
      queryFn: () =>
        this.load$<ISummaryCommits>(queries, isMocked).pipe(map((res) => this.toChartSeries(res, pivotConfig)))
    });
  }

  public getGerritChangesetsChart(
    queries: Query[],
    filters: InsightsFilters,
    pivotConfig: PivotConfig,
    isMocked: boolean = false
  ) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_GERRIT_CHANGESETS_CHART,
        filters.project,
        filters.repositories,
        filters.hideBots,
        queries[0].timeDimensions
      ],
      queryFn: () =>
        this.load$<IGerritChangesetsChart>(queries, isMocked).pipe(map((res) => this.toChartSeries(res, pivotConfig)))
    });
  }
}

// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

export enum BffConstants {
  GLOBAL_YEARLY_STATS = 'global/yearly-stats',
  CONTRIBUTORS_LEADERBOARD = 'contributors/leaderboard',
  ORGANIZATION_LEADERBOARD = 'organizations/leaderboard',
  PROJECT_HEALTH_FORKS = 'project-health/forks',
  PROJECT_HEALTH_STARS = 'project-health/stars',
  PROJECT_HEALTH_SCORES = 'project-health/scorecard',
  CONTRIBUTOR_BUS_FACTOR = 'contributors/bus-factor',
  ORG_BUS_FACTOR = 'organizations/elephant-factor',
  GROUPS_IO_CONTRIBUTOR_COUNTRY = 'groupsio/contributor-country',
  GROUPS_IO_TOP_MAILING_LISTS = 'groupsio/top-mailing-lists',
  GROUPS_IO_NEW_CONTRIBUTORS = 'groupsio/new-contributors',
  GROUPS_IO_ACTIVE_CONTRIBUTORS = 'groupsio/active-contributors',
  GROUPS_IO_NEW_ORGANIZATION = 'groupsio/new-organizations',
  GROUPS_IO_ACTIVE_ORGANIZATION = 'groupsio/active-organizations',
  GROUPS_IO_TOP_TILES = 'groupsio/simple-metrics',
  GROUPS_IO_SUMMARY_CHARTS = 'groupsio/metrics-over-time',
  GROUPS_IO_POPULAR_THREADS = 'groupsio/popular-threads',
  GROUPS_IO_RECENT_MESSAGES = 'groupsio/recent-messages',
  GROUPS_IO_MAILING_LISTS = 'groupsio/mailing-lists',
  PRODUCTIVITY_DRIFTING_AWAY_CONTRIBUTORS = 'contributors/drifting-away',
  PRODUCTIVITY_NEW_CONTRIBUTORS = 'contributors/new-activities',
  PRODUCTIVITY_ENGAGEMENT_GAP = 'productivity/pull-request-engagement-gap',
  GLOBAL_CONTRIBUTORS = 'global/contributors',
  GLOBAL_COMMITS = 'global/commits',
  GLOBAL_ISSUES = 'global/issues-opened',
  GLOBAL_PR = 'global/prs-opened',
  GLOBAL_FORKS = 'global/forks',
  GLOBAL_STARS = 'global/stars',
  GLOBAL_CARDS = 'global/cards',
  GLOBAL_OVERVIEW = 'global/overview',
  GLOBAL_PROJECT_SEGMENTS = 'global/project-segments',
  GLOBAL_PROJECTS = 'global/projects',
  GLOBAL_REPOSITORIES = 'global/segment-repos'
}

// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { map } from 'rxjs';
import { PivotConfig, Query } from '@cubejs-client/core';

import { CubeServiceBasic } from '@services/api/basic';
import { InsightsFilters } from '@services/filter.service';
import { ICacheKey, InjectQuery } from '@services/api/api.types';
import { CubeService } from '../../cube.service';

export interface IActivitiesCommitCount {
  ['SnowCommitsOverview.totalCommitsMainBranch']: number;
}

export interface IActivitiesCommitTimestamp {
  ['SnowActivities.commit_timestamp']?: string;
  ['SnowActivities.commit_timestamp_string']?: string;
  ['SnowActivities.commit_timezone']?: string;
  convertedTime?: string;
}

export interface ISummaryCommits {
  ['x']: string; // contains date
  ['SnowCommitsOverview.totalCommitsMainBranch']: number;
  ['SnowCommitsOverviewCumulative.cumulative_commits']: number;
}

export interface ICommitsPerDay {
  ['x']: string; // contains date
  ['SnowActivities.metric_commits']: number;
}

export interface ICommitsPerDayVariation {
  ['SnowActivities.avg_commit_activedays']: string;
}

export class CommitsApiClass extends CubeServiceBasic {
  protected useQuery: InjectQuery;
  constructor(cubejs: CubeService, useQuery: InjectQuery) {
    super(cubejs);
    this.useQuery = useQuery;
  }

  public getActivitiesCommitTimestamp(query: Query, filters: InsightsFilters, isMocked: boolean = false) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_ACTIVE_COMMIT_TIMESTAMP,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () =>
        this.load$<IActivitiesCommitTimestamp>(query, isMocked).pipe(map((res) => this.toTableCompare(res)))
    });
  }

  public getCommitPerDay(query: Query, filters: InsightsFilters, pivotConfig?: PivotConfig) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_COMMITS_PER_DAY,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<ICommitsPerDay>(query).pipe(map((res) => this.toChartSeries(res, pivotConfig)))
    });
  }

  public getCommitPerDayVariation(query: Query, filters: InsightsFilters) {
    return this.useQuery({
      queryKey: [
        ICacheKey.GET_COMMITS_PER_DAY_VARIATION,
        filters.project,
        filters.repositories,
        filters.hideBots,
        query.timeDimensions
      ],
      queryFn: () => this.load$<ICommitsPerDayVariation>(query).pipe(map((res) => this.toTable(res)))
    });
  }
}

// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { ICacheKey, InjectInfiniteQuery, InjectQuery } from '@services/api/api.types';
import { BffConstants } from '@services/api/bff.constants';
import { BFFServiceBasic } from '@services/api/bff.basic';
import { BffService } from '@services/bff.service';
import { InsightsFiltersNew } from '@services/filter/filter.types';
import { InsightProject } from 'lfx-insights';
import { ActivatedRoute } from '@angular/router';
import { inject } from '@angular/core';
import { getTileQueryParam } from '@app/shared/queries/summary-charts/query';

export interface IContributorsTotal {
  CONTRIBUTORS: number;
  PREV_CONTRIBUTORS: number;
  DELTA_CONTRIBUTORS: number;
}

export interface ICommitsTotal {
  COMMITS: number;
  PREV_COMMITS: number;
  DELTA_COMMITS: number;
}

export interface IIssuesTotal {
  ISSUES: number;
  PREV_ISSUES: number;
  DELTA_ISSUES: number;
}

export interface IPRsTotal {
  PRS: number;
  PREV_PRS: number;
  DELTA_PRS: number;
}

export interface IForksTotal {
  FORKS: number;
  PREV_FORKS: number;
  DELTA_FORKS: number;
}

export interface IStarsTotal {
  STARS: number;
  PREV_STARS: number;
  DELTA_STARS: number;
}

export class SummaryTilesApiClass extends BFFServiceBasic {
  protected useInfiniteQuery;
  protected useQuery: InjectQuery;

  private activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  private cacheByPass =
    (this.activatedRoute.snapshot.queryParamMap.has('cache-bypass') &&
      this.activatedRoute.snapshot.queryParamMap.get('cache-bypass') === 'true') ||
    undefined;

  constructor(bffService: BffService, useInfiniteQuery: InjectInfiniteQuery, useQuery: InjectQuery) {
    super(bffService);
    this.useInfiniteQuery = useInfiniteQuery;
    this.useQuery = useQuery;
  }

  public getTotalContributorCount(filters: InsightsFiltersNew, project: InsightProject) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_TOTAL_CONTRIBUTOR_COUNT, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getTileQueryParam(
          filters,
          project,
          ['CONTRIBUTORS', 'PREV_CONTRIBUTORS', 'DELTA_CONTRIBUTORS'],
          this.cacheByPass
        );
        return this.loadData<IContributorsTotal[]>(BffConstants.GLOBAL_CONTRIBUTORS, query);
      }
    });
  }

  public getTotalCommitsCount(filters: InsightsFiltersNew, project: InsightProject) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_TOTAL_COMMIT_COUNT, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getTileQueryParam(
          filters,
          project,
          ['COMMITS', 'PREV_COMMITS', 'DELTA_COMMITS'],
          this.cacheByPass
        );
        return this.loadData<ICommitsTotal[]>(BffConstants.GLOBAL_COMMITS, query);
      }
    });
  }

  public getTotalIssuesCount(filters: InsightsFiltersNew, project: InsightProject) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_TOTAL_ISSUES_COUNT, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getTileQueryParam(filters, project, ['ISSUES', 'PREV_ISSUES', 'DELTA_ISSUES'], this.cacheByPass);
        return this.loadData<IIssuesTotal[]>(BffConstants.GLOBAL_ISSUES, query);
      }
    });
  }

  public getTotalPRCount(filters: InsightsFiltersNew, project: InsightProject) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_TOTAL_PR_COUNT, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getTileQueryParam(filters, project, ['PRS', 'PREV_PRS', 'DELTA_PRS'], this.cacheByPass);
        return this.loadData<IPRsTotal[]>(BffConstants.GLOBAL_PR, query);
      }
    });
  }

  public getTotalForksCount(filters: InsightsFiltersNew, project: InsightProject) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_TOTAL_FORKS_COUNT, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getTileQueryParam(filters, project, ['FORKS', 'PREV_FORKS', 'DELTA_FORKS'], this.cacheByPass);
        return this.loadData<IForksTotal[]>(BffConstants.GLOBAL_FORKS, query);
      }
    });
  }

  public getTotalStarsCount(filters: InsightsFiltersNew, project: InsightProject) {
    return this.useQuery({
      queryKey: [ICacheKey.GET_TOTAL_STARS_COUNT, filters, project.id, this.cacheByPass] as const,
      queryFn: () => {
        const query = getTileQueryParam(filters, project, ['STARS', 'PREV_STARS', 'DELTA_STARS'], this.cacheByPass);
        return this.loadData<IStarsTotal[]>(BffConstants.GLOBAL_STARS, query);
      }
    });
  }
}

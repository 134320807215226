// Copyright The Linux Foundation and each contributor to LFX.
// SPDX-License-Identifier: MIT

import { InsightsFiltersNew } from '@app/shared/services/filter/filter.types';
import { getTimeQueryParams } from '@app/shared/utils/page-helpers';
import { GranularityEnum, InsightProject } from 'lfx-insights';

interface SummaryQueryParam {
  cacheBypass?: boolean;
  fields?: string;
  segmentId: string;
  repository?: string;
  timeRangeName?: string;
  filterBots?: number;
  ctr?: string;
  dateFrom?: string;
  dateTo?: string;
  granularity?: GranularityEnum;
  subProject?: string;
  project?: string;
  projectGroup?: string;
}

export const getTileQueryParam = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  fields: string[],
  cacheBypass?: boolean
): SummaryQueryParam => {
  const { timeRangeName, dateFrom, dateTo } = getTimeQueryParams(filter);

  const tmp: SummaryQueryParam = {
    cacheBypass,
    fields: fields.join(','),
    segmentId: project.id,
    timeRangeName: timeRangeName === 'custom' ? '' : timeRangeName,
    filterBots: filter.hideBots ? 1 : 0
  };

  if (timeRangeName === 'custom') {
    tmp.ctr = 'hll';
    tmp.dateFrom = dateFrom;
    tmp.dateTo = dateTo;
  }

  return tmp;
};

export const getChartQueryParam = (
  filter: InsightsFiltersNew,
  project: InsightProject,
  fields: string[],
  cacheBypass?: boolean
): SummaryQueryParam => {
  const { timeRangeName, dateFrom, dateTo } = getTimeQueryParams(filter);

  const tmp: SummaryQueryParam = {
    cacheBypass,
    fields: `BUCKET_DT_FROM,BUCKET_DT_TO,IS_SUMMARY,${fields.join(',')}`,
    segmentId: project.id,
    timeRangeName: timeRangeName === 'custom' ? '' : timeRangeName,
    filterBots: filter.hideBots ? 1 : 0,
    granularity: filter.granularity
  };

  if (timeRangeName === 'custom') {
    tmp.ctr = 'hll';
    tmp.dateFrom = dateFrom;
    tmp.dateTo = dateTo;
  }

  return tmp;
};
